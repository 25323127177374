<template>
  <!-- 默认充值方式 -->
  <div>
    <el-button style="cursor: pointer; margin-top: -5px" type="primary" class="paycode" size="mini" @click="showRecharge, (dialogVisible = true)">立即充值</el-button>
    <el-dialog title="余额充值" :visible.sync="dialogVisible" width="45%" @close="closePayDialog" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="content">
        <div class="name">
          充值账户：<span>{{ accountzi }}</span>
        </div>
        <div class="price">
          <!-- 充值金额： -->
          <div class="packing">
            <div @click="selectAmount(index, item)" class="packing-item" :class="{ active: selectAmountIndex === index }" v-for="(item, index) in rechargeAmount" :key="index">
              {{ item }}元
              <img class="dagou" src="~assets/icons/dagou.png" />
            </div>
          </div>
        </div>
        <br />
        <div class="montext">
          自定义金额:
          <div class="input-num" style="margin-left: 30px">
            <div>
              <el-input-number @mouseenter.native="controls = true" @mouseleave.native="controls = false" placeholder="请输入充值金额,最低1元" :controls="controls" width="300" ref="numInput" v-model="num" size="small" :precision="2" @change="handleChange" :min="1"></el-input-number>
            </div>
            <span class="queding">其他金额请手动输入</span>
          </div>
        </div>
        <div>
          <div class="payway-btn">
            <span>支付方式：</span>
            <div class="way">
              <div class="payway" @click="selectAmount1(index, item.value)" :class="{ active: selectzhi === index }" v-for="(item, index) in rechargezhi" :key="index">
                <img class="yue" src="~assets/icons/yue.png" /> <img class="dagou" src="~assets/icons/dagou.png" />
                <span>{{ item.name }}</span>
              </div>
            </div>
            <el-link type="success">推荐使用支付宝支付</el-link>
          </div>
          <div><el-row :gutter="20" v-if="isDisplay" v-show="isCode"><el-col><div v-show="selectzhi === 0 ? isDisplay : false" class="grid-content bg-purple">
          <img class="codeimgs" :src="qianHtml" /><br /><img class="codeimgs" :src="faceHtml" /><br />
          <img class="codeimgs" :src="jlmHtml" /><br />
          <span v-show="!show" class="count qrcode">支付剩余时间{{ count }} s</span><br /><!-- <span v-show="show" @click="getCode">获取验证码</span> -->
          <span style="position: absolute; margin: 35px 0"> <el-link type="primary">请使用支付宝支付</el-link>&nbsp;
          <el-link type="warning">请严格按照扫码后的金额付款 否则可能造成充值不到账等异常</el-link> </span><br /></div>
          <!--  -->
          <div v-show="selectzhi === 1 ? isDisplay : false" class="grid-content bg-purple"><img class="codeimgs" :src="jlmCode" /><br />
          <span v-show="!show" class="count qrcode">支付剩余时间{{ count }} s</span><br /><!-- <span v-show="show" @click="getCode">获取验证码</span> -->
          <span style="position: absolute; margin: 35px 0"> <el-link type="success"><p style="font-size:18px;">请使用微信支付</p></el-link>&nbsp;
          <el-link type="warning">请严格按照扫码后的金额付款 否则可能造成充值不到账等异常</el-link> </span><br /></div><!--  --></el-col> </el-row><br /><br /></div>
        </div>
        <div class="input-num">
          <p v-if="this.faceShow == 'fmpay'">
            <span @click="confirmRecharge" class="btn fmpay"> 确认充值 </span>
          </p>
          <p v-if="this.faceShow == 'facepay'">
            <span @click="faceToFaceDisplay" class="btn2 facepay">支付宝扫码支付</span>
          </p>
          <p v-if="this.faceShow == 'vpay'">
            <span @click="changeDisplay" class="btn2 vpay" v-show="isCode && selectzhi == 0">扫码充值</span>
          </p>
          <p v-if="this.faceShow == 'jlmpay'">
            <span @click="jlmPlay" class="btn2 jlmpay">扫码充值</span>
          </p>

        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { topuprice, moneyInfo, getSubTrad, getUserMoney, vPayQr, qrCheck, faceMsg, faceAlipay, jlmPay, jlmMsg } from 'network/api'
// import {topup} from "../../../network/api";
import { Message } from 'element-ui'
export default {
  inject: ['mainReload', 'reload'],
  data() {
    return {
      isShow: false,
      isCode: true, // 显示支付宝扫码支付false
      show: true,
      count: '',
      qianHtml: '',
      faceHtml: '',
      jlmHtml: '',
      jlmCode: '',
      isDisplay: false,
      redialogVisible: false,
      activeIndex: '0',
      dialogVisible: false, // 余额充值
      tixianDialogVisible: false,
      rechargeAmount: ['10', '50', '100', '500', '1000', '2000'],
      rechargezhi: [
        {
          name: '支付宝支付',
          value: 'alipay'
        },
        {
          name: '微信支付',
          value: 'wechat'
        }
      ],
      faceShow: '',
      payShow: false,
      payType: '',
      amountType: 'alipay',
      selectAmountIndex: 2,
      selectzhi: 0,
      num: 100, // undefined
      tixianNum: 1,
      controls: false,
      tixianControls: false,
      moneyObj: {},
      alipay: '',
      accountzi: '',
      trueName: '',
      currentPage: 1,
      moneylogId: '',
      reasonVal: '',
      id: '',
      input: '',
      imgUrl: '',
      price: '',
      timer: null,
      timNum: 0,
      awardName: '',
      awardUp: '',
      groupData: '',
      live: ''
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    // payTypeShow() {
    //   // eslint-disable-next-line eqeqeq
    //   if (this.payType == 'vpay') {
    //     // console.log(this.payType)
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.rechargezhi = [
    //       {
    //         name: '支付宝支付',
    //         value: 'alipay'
    //       }
    //     ]
    //     return false
    //     // eslint-disable-next-line eqeqeq
    //   } else if (this.payType == 'fmpay') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     return true
    //   }
    // }
  },
  created() {
    if (this.selectAmountIndex === 2) {
      this.num = this.rechargeAmount[2]
    }
    // 获取支付宝账号
    getUserMoney().then(res => {
      if (res.code !== 1) {
        return this.$message.warning(res.msg)
      }
      this.accountzi = res.data.username
      this.alipay = res.data.ali_pay
      this.trueName = res.data.true_name
    })
    const face = JSON.parse(localStorage.getItem('siteInfo'))
    this.faceShow = face.pay_type
  },
  methods: {
    // 支付剩余时间
    getCode() {
      const TIME_COUNT = 180
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    changeDisplay() {
      this.count = ''
      this.isDisplay = !this.isDisplay
      setTimeout(() => {
        this.qrCode()
        // this.getCode()
      }, 500)
      // 扫码支付
    },
    faceToFaceDisplay() {
      this.count = ''
      this.isDisplay = !this.isDisplay
      setTimeout(() => {
        this.faceToFace()
      }, 500)
      // 扫码支付
    },
    qrCode() {
      const price = this.num
      if (price === undefined || '') {
        return false
      }
      vPayQr(price).then(res => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.getCode()
          this.qianHtml = res.data.payQRcode
          const orderId = res.data.orderId
          setTimeout(() => {
            this.timer = setInterval(() => {
              this.timNum = this.timNum + 1
              // eslint-disable-next-line eqeqeq, no-self-compare
              if (this.count == 0) {
                this.dialogVisible = false
                clearInterval(this.timer)
                this.$notify.warning({ title: '充值超时', duration: 5000 })
                return false
              }
              qrCheck(orderId).then(res => {
                if (res.code !== 1) {
                  setTimeout(() => {
                    // this.$notify.warning({ title: res.msg, duration: 2000 })
                  }, 1000)
                }
                if (res.code === 1) {
                  clearInterval(this.timer)
                  this.timer = null
                  setTimeout(() => {
                    this.dialogVisible = false
                  }, 2000)
                  return Message.success(res.msg)
                }
              })
            }, 3000)
          }, 5000)
        }
      })
    },
    // 支付宝面对面付
    faceToFace() {
      const amount = this.num
      if (amount === undefined || '') {
        return false
      }
      faceAlipay(amount).then(res => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.getCode()
          this.faceHtml = res.data.qrcode
          const orderId = res.data.orderid
          setTimeout(() => {
            this.timer = setInterval(() => {
              this.timNum = this.timNum + 1
              // eslint-disable-next-line eqeqeq, no-self-compare
              if (this.count == 0) {
                this.dialogVisible = false
                clearInterval(this.timer)
                this.$notify.warning({ title: '充值超时', duration: 5000 })
                return false
              }
              faceMsg(orderId).then(res => {
                if (res.code !== 1) {
                  setTimeout(() => {
                    // this.$notify.warning({ title: res.msg, duration: 2000 })
                  }, 1000)
                }
                if (res.code === 1) {
                  clearInterval(this.timer)
                  // this.timer = null
                  setTimeout(() => {
                    this.dialogVisible = false
                  }, 2000)
                  return Message.success(res.msg)
                }
              })
            }, 3000)
          }, 5000)
        }
      })
    },

    // jlm
    jlmPlay() {
      this.count = ''
      this.isDisplay = !this.isDisplay
      setTimeout(() => {
        this.jlm()
      }, 500)
      // 扫码支付
    },
    jlm() {
      const amount = this.num
      if (amount === undefined || '') {
        return false
      }
      jlmPay(amount, this.amountType).then(res => {
        if (res.code !== 1) {
          return this.$message.warning(res.msg)
        }
        if (res.code === 1) {
          this.getCode()
          // eslint-disable-next-line eqeqeq
          if (this.amountType == 'alipay') {
            this.jlmHtml = res.data.qr
          // eslint-disable-next-line eqeqeq
          } else if (this.amountType == 'wechat') {
            this.jlmCode = res.data.qr
          }
          const id = res.data.id
          setTimeout(() => {
            this.timer = setInterval(() => {
              // eslint-disable-next-line eqeqeq
              if (this.count == 0) {
                clearInterval(this.timer)
                this.$notify.warning({ title: '充值超时', duration: 5000 })
                this.dialogVisible = false
                // return false
              }
              jlmMsg(id).then(res => {
                console.log(res)
                if (res.code !== 1) {
                  setTimeout(() => {
                    // this.$notify.warning({ title: res.msg, duration: 2000 })
                  }, 1000)
                }
                if (res.code === 1) {
                  clearInterval(this.timer)
                  // this.timer = null
                  setTimeout(() => {
                    this.dialogVisible = false
                  }, 2000)
                  return Message.success(res.msg)
                }
              })
            }, 1000)
          }, 1000)
        }
      })
    },

    // 提交充值按钮
    submit() {
      getSubTrad({ trade: this.input }).then(res => {
        if (res.code === 1) {
          this.$message({ message: '充值成功', type: 'success' })
        } else {
          return this.$message.error({ message: res.msg })
        }
      })
    },
    // 刷新按钮
    renovate() {
      moneyInfo().then(res => {
        if (res.code === 1) {
          this.balanceInfo = res.data
          this.$message({ message: '刷新成功', type: 'success' })
        } else {
          this.$message.error({ message: '刷新失败' })
        }
        // console.log(res)
      })
    },
    // 马上充值
    showRecharge() {
      this.dialogVisible = true
    },
    recharge() {
      this.redialogVisible = true
      this.imgUrl = this.$store.getters.getSiteInfo.alipay_paymentcode
    },
    // 选择充值金额点击
    selectAmount(index, item) {
      this.selectAmountIndex = index
      this.num = item
    },
    // 充值方式
    selectAmount1(index, value) {
      this.selectzhi = index
      this.amountType = value
      // 1 'wechat'
    },
    // 关闭Dialog回调
    closePayDialog() {
      clearInterval(this.timer)
      // this.count = 11
      // this.timer = null
      // console.log('关闭')
      setTimeout(() => {
        this.refresh()
      }, 300)
    },
    refresh() {
      this.reload()
    },
    // 计数器值改变
    handleChange(val) {},
    // 确认充值
    confirmRecharge() {
      if (this.num) {
        topuprice(this.num.toString(), this.amountType).then(res => {
          if (res.code !== 200) {
            return this.$message(res.msg)
          }
          if (res.code === 200) {
            window.open(res.data.payUrl, '_blank')
            this.$nextTick(() => {
              this.dialogVisible = false
            })
          }
        })
      } else {
        this.$message({
          message: '请输入金额',
          type: 'warning'
        })
      }
    },
    // 关闭提现Dialog回调
    closeTixianDialog() {},
    // 提现点击
    showTixian() {
      this.tixianDialogVisible = true
    },
    // 查询
    clickQuery() {
      this.moneylogId = this.id
      this.getMoneylist()
    },
    // 重置
    clickReset() {
      this.mainReload()
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  }
}
</script>

<style scoped src="styles/views/account/balance.css"></style>

<style scoped lang="scss">
.content [data-v-0defb77c] {
  padding-left: 58px;
  padding-right: 58px;
  white-space: nowrap;
  overflow: overlay;
}

.montext {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
}
.awardName {
  // display: flex;
  justify-content: center;
  display: contents;
  font-size: 18px;
  color: #ee7e31;
  right: 10px;
}
$colors: #e8e8e8;
$colour: red;
@mixin public($colors) {
  color: $colors;
}
@mixin titles() {
  font: {
    size: 16px;
    weight: 600;
  }
}
.codeimgs {
  position: relative;
  margin-left: 150px;
  margin-top: 20px;
  /* text-align: center; */
  border: none;
  /* margin-top: -48px; */
  /* justify-content: center; */
  /* align-items: center; */
  border: 1px solid red;
}
.count {
  font-size: 23px;
  margin-bottom: 20px;
}
.qrcode {
  position: absolute;
  margin-left: 150px;
}
.recharge-hint {
  border: 1px solid $colors;
  padding: 15px 15px 0 15px;
  div {
    padding-bottom: 15px;
    font-weight: 600;
    color: black;
    font: {
      size: 16px;
      weight: 600;
    }
  }
  p {
    padding-bottom: 10px;
    @include public(black);
  }
}
.recharge-title {
  margin-top: 15px;
  &-tag {
    @include titles();
  }
  &-text {
    @include titles();
  }
}
.recharge-main {
  display: flex;
  &-img {
    padding: 5% 10% 10% 10%;
    border-right: 1px solid $colors;
    img {
      width: 200px;
      height: 200px;
      border-radius: 10px;
      border: 5px solid #ee7e31;
    }
  }
  &-right {
    width: 100%;
    padding: 40px;
    .main-right-balance {
      padding: 10px 20px 10px 15px;
      background-color: #fff6f7;
      border: 1px solid $colour;
      border-radius: 10px;
      i {
        font-size: 30px;
        color: $colour;
      }
      p {
        color: $colour;
        font-weight: 600;
        margin-top: 10px;
      }
      .main-right-freshen {
        float: right;
        position: relative;
        top: -40px;
      }
    }
  }
}

.rechargeDialog .el-dialog__header,
.tixianDialog .el-dialog__header {
  border-bottom: 1px solid $colors;
}

.input-num .el-input-number--small {
  width: 180px;
}

.input-item.number .el-input-number {
  width: 324px;
}
</style>
